<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">

    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="结算时间 :">
            <el-date-picker
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="searchData.selectTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="search">
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-select v-model="searchData.searchType"
                       placeholder="请选择关键字类型"
                       style="width: 120px">
              <el-option
                v-for="item in searchKeyType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input class=""
                      placeholder="搜索关键字"
                      prefix-icon="el-icon-search"
                      v-model="searchData.searchValue"
                      @keyup.enter.native="search"
                      style="width: 260px">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-select v-model="searchData.type"
                       placeholder="类型"
                       @change="search"
                       style="width: 120px">
              <el-option
                v-for="item in detailedSettlementTypeArray"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="downloadExcel" icon="el-icon-download">导出</el-button>
          </el-form-item>
        </el-form>
        <el-tabs v-model="searchData.incomeType" @tab-click="handleClick">
          <el-tab-pane label="收入" name="1"></el-tab-pane>
          <el-tab-pane label="支出" name="2"></el-tab-pane>
        </el-tabs>
        <div style="text-align: center;color: red; ">
          <span style="padding-right: 35px;"
                v-if="showStartEndTime"> {{showStartEndTime[0]}}至{{showStartEndTime[1]}} </span>
          <span style="padding-right: 35px;">总收入：{{searchData.incomeAmount }}元</span>
          <span>总支出：{{searchData.expensesAmount }}元</span>

        </div>

      </template>

      <template v-slot:table="row">
        <el-table
          :data="tableData"
          :height="row.heightText"
          v-if="row.height">
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
          </el-table-column>
          <el-table-column
            prop="id"
            label="明细编号"
            width="200">
          </el-table-column>
          <el-table-column
            label="明细类型"
            width="140">
            <template slot-scope="scope">
              {{detailedSettlementType[scope.row.type]}}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime_text"
            label="结算时间"
            width="200">
          </el-table-column>
          <el-table-column
            prop="amount"
            label="金额(元)"
            width="150">
          </el-table-column>
          <el-table-column
            prop="relateId"
            label="关联单号"
            width="200">
          </el-table-column>
          <el-table-column
            prop="orderId"
            label="订单号"
            width="200">
          </el-table-column>
          <el-table-column
            label="收支类型"
            width="140">
            <template slot-scope="scope">
              {{incomeTypeStatus[scope.row.incomeType]}}
            </template>
          </el-table-column>

        </el-table>
      </template>

      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum">
          </el-pagination>
        </div>
      </template>

    </table-height-auto>
  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {disposeSearchData, getNextDayTimestamp, isEmpty, parseTime, parseTimeArray} from '../../../assets/js/utils'
  import {detailedSettlementType, detailedSettlementTypeArray} from '../../../assets/js/status.js'
  import {plusDownloadExcelTask} from '../../../store/excel'

  export default {
    name: 'supplierWalletGetSupplierBill',
    components: {},
    data() {
      return {
        showStartEndTime: null,
        loading: false,
        tableData: [],
        searchData: {
          searchType: 'id',
          searchValue: '',
          selectTime: [],
          incomeType: '1',
          type: 0,
          searchData: '',
          expensesAmount: '0',
          incomeAmount: '0',
        },
        detailedSettlementType,
        detailedSettlementTypeArray,
        searchKeyType: [
          {id: 'id', name: '明细编号'},
          {id: 'supplierId', name: '关联单号'},
          {id: 'relateId', name: '订单号'},
        ],
        incomeTypeStatus: {
          1: '收入',
          2: '支出'
        },
        /* 分页参数 */
        pageSize: 10,
        totalListNum: 10,
        currentPage: 1,

      }
    },
    created() {
      let endText = new Date();
      let startText = new Date();
      startText.setTime(startText.getTime() - 3600 * 1000 * 24 * 30);
      this.searchData.selectTime = [parseTime(startText), parseTime(endText)];
      this.showStartEndTime = this.searchData.selectTime;

      this.getList();
      this.getStatistics();
    },

    methods: {
      handleClick(tab, event) {
        this.currentPage = 1;
        this.getList()
        console.log(tab, event);
      },
      /**
       * 拼接搜索参数
       */
      jointSearchData() {
        let data = {
          pageNo: this.currentPage,
          pageSize: this.pageSize,
        };
        data = Object.assign(data, this.searchData);
        disposeSearchData(data);
        let selectTime = data.selectTime;
        let startTime = 0;
        let endTime = 0;

        if (!isEmpty(selectTime)) {
          let times = selectTime.map(res => new Date(res).getTime())
          startTime = times[0];
          endTime = getNextDayTimestamp(times[1]);
        }

        data.startTime = startTime;
        data.endTime = endTime;

        delete data.selectTime;
        return data;
      },
      /**
       * 搜索
       */
      search() {
        this.currentPage = 1;
        this.getList();
        this.getStatistics();
        this.showStartEndTime = this.searchData.selectTime;
      },
      getList() {
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.suppWallet.getSupplierBills, {params: data}).then(res => {
            let list = res.data.records || [];

            parseTimeArray(list, ['createTime'])
            this.tableData = list;
            this.totalListNum = Number(res.data.total);
            resolve(res);
          }).catch(res => {
            this.tableData = [];
          }).finally(res => {
            this.loading = false;
          })
        })
      },

      getStatistics() {
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.suppWallet.getSupplierBillSum, {params: data}).then(res => {
            // let list = res.data || [];
            this.searchData.expensesAmount = res.data.expensesAmount;
            this.searchData.incomeAmount = res.data.incomeAmount;
            // console.log(data);
            resolve(res);
          }).catch(res => {
            this.tableData = [];
          }).finally(res => {
            this.loading = false;
          })
        })
      },

      /* 分页 */
      handleSizeChange(val) {
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getList();
      },
      /**
       * 导出excel
       */
      downloadExcel() {
        let searchData = this.jointSearchData();
        let data = {
          name: '供应商收支流水明细报表',
          params: {
            excelType: 1004,
            excelParameterJson: JSON.stringify(searchData),
          },
        };
        plusDownloadExcelTask.call(this, data);
      },
    }
  }

</script>

<style>
</style>
